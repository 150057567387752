/**
 * This module imports the Froala editor and all plugins. It's currently just a convenience that
 * allows you to switch to using the non-minified version by changing only a single place in the code. At some
 * point we may hand select which Froala plugins we ship with rather than including all available plugins
 * (in the .pkgd file), and this would be the only place that would need to change.
 *
 * @exports Object containing FroalaEditor
 */

import FroalaEditor from 'froala-editor/js/froala_editor.pkgd.min.js';
import de from 'other_components/languages/de.json';

FroalaEditor.LANGUAGE['de'] = de;

export { FroalaEditor };
